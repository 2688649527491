import {
	BoxGeometry,
	EdgesGeometry,
	LineBasicMaterial,
	LineSegments,
	Mesh,
	MeshBasicMaterial
} from "three";

const studMaterial = new MeshBasicMaterial({ color: 0xEC4D37 });
const sheathingMaterial = new MeshBasicMaterial({ color: 0xeca837 });
const lineMat = new LineBasicMaterial({ color: 0x000000 });

//const lengths = [6, 8, 10, 12, 14, 16, 18, 20, 22, 24]
const studs = {
	// One-By
	OneByTwo: [0.75, 1.5],
	OneByThree: [0.75, 2.5],
	OneByFour: [0.75, 3.5],
	OneBySix: [0.75, 5.5],
	OneByEight: [0.75, 7.25],
	OneByTen: [0.75, 9.25],
	OneByTwelve: [0.75, 11.25],
	// Two-By
	TwoByTwo: [1.5, 1.5],
	TwoByFour: [1.5, 3.5],
	TwoBySix: [1.5, 5.5],
	TwoByEight: [1.5, 7.25],
	TwoByTen: [1.5, 9.25],
	TwoByTwelve: [1.5, 11.25],
	// Four-By
	FourByFour: [3.5, 3.5],
	FourBySix: [3.5, 5.5],
	FourByEight: [3.5, 7.5],
	FourByTen: [3.5, 9.25],
	FourByTwelve: [3.5, 11.25],
	// Six-By
	SixBySix: [5.5, 5.5],
	// Eight-By
	EightByEight: [7.25, 7.25],
}

const sheathing = {
	OneEighth: 7/64,
	OneQuarter: 7/32,
	ThreeEighth: 11/32,
	OneHalf: 15/32,
	FiveEighths: 19/32,
	ThreeQuarters: 23/32,
	OneAndOneEighth: 1.125,
}

export default {
	Studs: studs,
	Sheathing: sheathing,

	// Construct a stud by type and length
	// ex: BuildStud("TwoByFour", 24 * 12)
	BuildStud(type, l) {
		if (type in studs) {
			// Build a stud
			const [h, w] = studs[type];
			const geometry = new BoxGeometry(w, h, l);
			const stud = new Mesh(geometry, studMaterial);
			const edges = new EdgesGeometry(geometry);
			const lines = new LineSegments(edges, lineMat);
			return [stud, lines];
		} else {
			console.log("Unknown stud dimension or length.")
			return false;
		}
	},
		
	BuildSheathing(type, w, t) {
		if (type in sheathing) {
			// Build sheathing
			const h = sheathing[type];
			const geometry = new BoxGeometry(h, t, w);
			const sheet = new Mesh(geometry, sheathingMaterial);
			const edges = new EdgesGeometry(geometry);
			const lines = new LineSegments(edges, lineMat);
			return [sheet, lines];
		} else {
			console.log("Unknown sheathing dimension or thickness.")
			return false;
		}
	},

	// Align stud from origin to world position
	Align(stud, vecFrom, vecTo, normal) {
		const mesh = stud[0];
		const lines = stud[1];
		mesh.up = normal;
		lines.up = normal;
		mesh.position.copy(vecFrom);
		lines.position.copy(vecFrom);
		mesh.lookAt(vecTo);
		lines.lookAt(vecTo);
		return [mesh, lines];
	},

	// Translate stud from current position
	Translate(stud, x, y, z) {
		const mesh = stud[0];
		const lines = stud[1];
		mesh.geometry.translate(x, y, z);
		lines.geometry.translate(x, y, z);
		return [mesh, lines];
	}
}