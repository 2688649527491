import Vue from 'vue'
import Router from 'vue-router'

import components from './../components'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // Home
    { name: 'root', path: '/', component: components.Home, props: true },
    { name: 'home', path: '/home', component: components.Home, props: true },

    // Default (if invalid route entered)
    { name: 'default', path: '*', component: components.Home, props: true }
  ]
})