import FloorBeam from "./FloorBeam";
import Frame from "./Rafter";
import Joist from "./Joist";
import LinearArray from "./LinearArray";
import Sheathing from "./Sheathing";
import { Group } from "three";

export default {
	Build(baseWidth, baseDepth, baseHeight, floorBeamStudSize, joistStudSize, rafterStudSize, roofSheathingSize) {
		// Create module
        let components = new Group();
        const joist = Joist.Build(baseWidth, joistStudSize, rafterStudSize);
        const rafter = Frame.Build(baseWidth, baseHeight, rafterStudSize);
        components.add(joist, rafter);

        // Apply linear array
        const array = LinearArray.Apply(components, baseDepth, 24); // Stud Spacing
        array.name = "house";

        // Add floor beam
        const floorBeam = FloorBeam.Build(baseWidth, baseDepth, floorBeamStudSize);
        
        // Add roof sheathing
        const roofSheathing = Sheathing.Build(baseWidth, baseDepth, baseHeight, roofSheathingSize, rafterStudSize);
        
        // TODO - clean this up
        components = new Group();
        components.add(array, floorBeam, roofSheathing);
        components.name = "house";
		return components;
	}
}