import Lumber from "./Lumber"
import {
	Group,
	Vector3,
} from "three";

export default {
	Build(baseWidth, baseDepth, height, sheathingSize, rafterStudSize) {
		let output = new Group();
		output.name = "sheathing";

		// Determine array dimensions
		const boardsPerRow = Math.floor(baseDepth / (8 * 12));
		//const rowCount = Math.floor(height / (4 * 12));

		const rafterStudWidth = Lumber.Studs[rafterStudSize][1];
		const sheathingThickness = Lumber.Sheathing[sheathingSize];

		// If less than the width of a single board - place trimmed board
		if (boardsPerRow < 1) {
			let sheet1 = Lumber.BuildSheathing("FiveEighths", 4 * 12, baseDepth);
			if (sheet1 !== false) {
				const start = new Vector3(-baseWidth / 2, 0, 0);
				const end = new Vector3(0, height, 0);
				const normal = new Vector3(0, 0, 1);
				sheet1 = Lumber.Align(sheet1, start, end, normal);
				sheet1 = Lumber.Translate(sheet1, rafterStudWidth / 2 + (sheathingThickness) / 2, 0, 12 * 2);
				output.add(sheet1[0], sheet1[1]);
			}
		}
		// If solution requires full and trimmed boards 
		else if (baseDepth % (8 * 12) !== 0) {
			// Sheets must be cut custom
		}
		// If solution requires full and half boards
		else if (baseDepth % (8 * 12) === 0 && (baseDepth / ( 8 * 12)) % 2 === 0 ) {
			let fullSheetUnit = new Group();
			let halfSheetUnit = new Group();
			fullSheetUnit.name = "full-sheet";
			halfSheetUnit.name = "half-sheet";
			let fullSheet = Lumber.BuildSheathing("FiveEighths", 4 * 12, 8 * 12);
			let halfSheet = Lumber.BuildSheathing("FiveEighths", 4 * 12, 4 * 12);
			
			if (fullSheet !== false && halfSheet !== false) {
				const start = new Vector3(-baseWidth / 2, 0, 0);
				const end = new Vector3(0, height, 0);
				const normal = new Vector3(0, 0, 1);
				fullSheet = Lumber.Align(fullSheet, start, end, normal);
				fullSheet = Lumber.Translate(fullSheet, rafterStudWidth / 2 + (sheathingThickness) / 2, 0, 12 * 2);
				halfSheet = Lumber.Align(halfSheet, start, end, normal);
				halfSheet = Lumber.Translate(halfSheet, rafterStudWidth / 2 + (sheathingThickness) / 2, 0, 12 * 2);
				fullSheetUnit.add(fullSheet[0], fullSheet[1]);
				halfSheetUnit.add(halfSheet[0], halfSheet[1]);
			}

			// Continue offsetting until length is covered
			for (let i = 0; i < boardsPerRow - 1; i++) {
				if ( i !== boardsPerRow - 2 || i === 0) {
					const leftSheet = fullSheetUnit.clone();
					const rightSheet = fullSheetUnit.clone();
					rightSheet.position.z = i * (8 * 12);
					leftSheet.position.z = -i * (8 * 12);
					output.add(leftSheet, rightSheet);
				} else {
					const leftSheet = halfSheetUnit.clone();
					const rightSheet = halfSheetUnit.clone();
					rightSheet.position.z = (i * (8 * 12)) - ( 2 * 12 );
					leftSheet.position.z = (-i * (8 * 12)) + ( 2 * 12 );
					output.add(leftSheet, rightSheet);
				}

				if( boardsPerRow - 1 === 1) {
					const leftSheet = halfSheetUnit.clone();
					const rightSheet = halfSheetUnit.clone();
					rightSheet.position.z = ((i + 1) * (8 * 12)) - ( 2 * 12 );
					leftSheet.position.z = ((-i - 1) * (8 * 12)) + ( 2 * 12 );
					output.add(leftSheet, rightSheet);
				}
			}
		}
		// Else solution uses only full/untrimmed boards
		else {
			let sheetUnit = new Group();
			sheetUnit.name = "full-sheet";
			// Place the first 8 x 4
			let sheet1 = Lumber.BuildSheathing("FiveEighths", 4 * 12, 8 * 12);
			if (sheet1 !== false) {
				const start = new Vector3(-baseWidth / 2, 0, 0);
				const end = new Vector3(0, height, 0);
				const normal = new Vector3(0, 0, 1);
				sheet1 = Lumber.Align(sheet1, start, end, normal);
				sheet1 = Lumber.Translate(sheet1, rafterStudWidth / 2 + (sheathingThickness) / 2, 0, 12 * 2);
				sheetUnit.add(sheet1[0], sheet1[1]);
			}

			// Continue offsetting until length is covered
			for (let i = 0; i < boardsPerRow - 1; i++) {
				const leftSheet = sheetUnit.clone();
				const rightSheet = sheetUnit.clone();
				rightSheet.position.z = i * (8 * 12);
				leftSheet.position.z = -i * (8 * 12);
				output.add(leftSheet, rightSheet);
			}
		}

		return output;
	}
}