import { Box3, Group } from "three";

export default {
	Apply(group, buildingDepth, studOffset) {
		let output = new Group();
		output.name = `${group.name}-array`;

        // Bounding box for single module unit
        const boundingBox = new Box3();
        boundingBox.setFromObject( group );
        const moduleWidth = boundingBox.max.z - boundingBox.min.z;
        const arrayCount = Math.floor((buildingDepth - (moduleWidth * 3)) / studOffset / 2) + 2;

		for(let i = 0; i < arrayCount; i++) {
            const frontMirror = group.clone();
            const backMirror = group.clone();
            if(i !== arrayCount - 1) {
                frontMirror.position.z = i * studOffset;
                backMirror.position.z = -i * studOffset;
            } else { // Trim final 2 components to meet build depth
                // TODO - the gap to the next stud can be more than the studOffset
                // specifically when the end stud meets the previous stud.
                // Instead, it should slide the end 2 studs together the moduleWidth
                // to ensure the stud gap is never larger than the studOffset
                frontMirror.position.z = buildingDepth / 2 + boundingBox.min.z;
                backMirror.position.z = -buildingDepth / 2 + boundingBox.max.z;
            }
            output.add(frontMirror, backMirror);
        }

		return output;
	}
}