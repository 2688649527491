import Vue from "vue"
import App from "./App.vue"
import PrimeVue from "primevue/config";
import VueGtag from 'vue-gtag';
import router from './router'

Vue.use(VueGtag, {
  config: { id: "G-G9DLGSJRVG" },
  appName: 'aframe.app',
  pageTrackerScreenviewEnabled: true
}, router);

Vue.use(PrimeVue);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router
}).$mount("#app")
