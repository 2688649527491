import Lumber from "./Lumber"
import {
	Group,
	Vector3,
} from "three";

export default {
	Build(baseWidth, joistStudSize, rafterStudSize) {
		let output = new Group();
		output.name = "joist";

		const joistStudWidth = Lumber.Studs[joistStudSize][1];
		const rafterStudThickness = Lumber.Studs[rafterStudSize][0];

		let joist1 = Lumber.BuildStud(joistStudSize, baseWidth);
		if (joist1 !== false) {
			const start = new Vector3(0, 0, 0);
			const end = new Vector3(baseWidth, 0, 0);
			const normal = new Vector3(0, 0, 1);
			joist1 = Lumber.Align(joist1, start, end, normal);
			joist1 = Lumber.Translate(joist1, joistStudWidth/2, -rafterStudThickness, 0);
			output.add(joist1[0], joist1[1]);
		}

		let joist2 = Lumber.BuildStud(joistStudSize, baseWidth);
		if (joist2 !== false) {
			const start = new Vector3(0, 0, 0);
			const end = new Vector3(baseWidth, 0, 0);
			const normal = new Vector3(0, 0, 1);
			joist2 = Lumber.Align(joist2, start, end, normal);
			joist2 = Lumber.Translate(joist2, joistStudWidth/2, rafterStudThickness, 0);
			output.add(joist2[0], joist2[1]);
		}
		return output;
	}
}