<template>
  <div>
    <NavBar class="stacked"
      :loginVisible="loginVisible"
      @updateModelProp="updateModelProp"
     />
    
    <div id="banner" class="stacked">A-Frame App</div>
    
    <WebGLViewer id="viewer" class="stacked"
      :modelVisible="modelVisible"
      :cameraType="cameraType"
      :gridVisible="gridVisible"
      :axesVisible="axesVisible"
      :buildingWidth="buildingWidth"
      :buildingHeight="buildingHeight"
      :buildingDepth="buildingDepth"
      :floorBeamStudSize="floorBeamStudSize"
      :joistStudSize="joistStudSize"
      :rafterStudSize="rafterStudSize"
      :roofSheathingSize="roofSheathingSize"
    />
    
    <DesignSettings
      :settingsVisible="settingsVisible"
      :modelVisible="modelVisible"
      :cameraType="cameraType"
      :gridVisible="gridVisible"
      :axesVisible="axesVisible"
      :buildingWidth="buildingWidth"
      :buildingHeight="buildingHeight"
      :buildingDepth="buildingDepth"
      @updateModelProp="updateModelProp"
    />
    
    <LoginMenu
      :loginVisible="loginVisible"
      @updateModelProp="updateModelProp"
    />
  </div>
</template>

<script>
import DesignSettings from "./DesignSettings.vue";
import LoginMenu from "./LoginMenu.vue";
import NavBar from "./NavBar.vue";
import WebGLViewer from "./WebGLViewer.vue";

export default {
  name: "HomePage",
  components: {
    DesignSettings,
    LoginMenu,
    NavBar,
    WebGLViewer,
  },
  data() {
    return {
      settingsVisible: false,
      gridVisible: true,
      axesVisible: false,
      loginVisible: false,
      modelVisible: true,
      cameraType: true, // perspective - false for ortho
      buildingWidth: 20 * 12,
      buildingHeight: (Math.sqrt(3) / 2) * (20 * 12),
      buildingDepth: 20 * 12,
      joistStudSize: "TwoByEight",
      rafterStudSize: "TwoBySix",
      floorBeamStudSize: "TwoByTen",
      roofSheathingSize: "FiveEighths",
    };
  },
  methods: {
    updateModelProp(settings) {
      const [prop, value] = settings
      if(value === "toggle") {
        this[prop] = !this[prop];
      } else {
        this[prop] = value;
      }
    }
  },
};
</script>

<style scoped>
.stacked {
  position: absolute;
}

#banner {
  position: absolute;
  left: 50%;
  color: #ec4d37;
  top: 21px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
</style>