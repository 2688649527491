<template>
  <Sidebar id="settings" 
    :visible="loginVisible"
    :baseZIndex="200" 
    position="right" 
    :showCloseIcon="false"
  >
    <!-- Close Button -->
    <div id="closeButton">
      <Button icon="pi pi-times" @click="closeSettings()" />
    </div>
    <div class="settingsHeader">
      <i class="pi pi-user" style="font-size: 16px" />
      User Settings
    </div>
    <hr />
    <br />
  </Sidebar>
</template>

<script>
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";

export default {
  name: "LoginMenu",
  props: ["loginVisible"],
  components: {
    Sidebar,
    Button
  },
  methods: {
    closeSettings() {
      this.$emit("updateModelProp", ["loginVisible", "toggle"]);
    }
  }
};
</script>

<style scoped>
#closeButton {
  position: absolute;
  top: 0px;
  left: 0px;
}

Button {
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px;
}

.p-button:enabled:hover {
  color: #EC4D37;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.p-button:focus {
  box-shadow: none;
}

.p-sidebar {
  margin-top: 42px;
  background-color: #1e1e1e !important;
  box-shadow: none !important;
}
</style>