import Lumber from "./Lumber"
import {
	Group,
	Vector3,
} from "three";

export default {
	Build(baseWidth, height, rafterStudSize) {
		let output = new Group();
		output.name = "rafter";

		// From the base size, calculate the roof rafter size
		let rafterLength = Math.sqrt(
			Math.pow(height, 2) + 
			Math.pow(baseWidth / 2, 2) -
			2 * height * (baseWidth / 2) *
			Math.cos(Math.PI / 2)
		);
		
		//const [rafterStudThickness, rafterStudWidth] = Lumber.Studs[rafterStudSize];

		let rafter1 = Lumber.BuildStud(rafterStudSize, rafterLength);
		if (rafter1 !== false) {
			const start = new Vector3(baseWidth / 2, 0, 0);
			const end = new Vector3(0, height, 0);
			const normal = new Vector3(0, 0, 1);
			rafter1 = Lumber.Align(rafter1, start, end, normal);
			rafter1 = Lumber.Translate(rafter1, 0, 0, rafterLength / 2);
			output.add(rafter1[0], rafter1[1]);
		}

		let rafter2 = Lumber.BuildStud(rafterStudSize, rafterLength);
		if (rafter2 !== false) {
			const start = new Vector3(-baseWidth / 2, 0, 0);
			const end = new Vector3(0, height, 0);
			const normal = new Vector3(0, 0, 1);
			rafter2 = Lumber.Align(rafter2, start, end, normal);
			rafter2 = Lumber.Translate(rafter2, 0, 0, rafterLength / 2);
			output.add(rafter2[0], rafter2[1]);
		}

		return output;
	}
}