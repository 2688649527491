<template>
  <Sidebar id="settings" :visible="settingsVisible" :baseZIndex="1000" position="left" :showCloseIcon="false">
    <!-- Close Button -->
    <div id="closeButton">
      <Button icon="pi pi-times" @click="updateModelProp('settingsVisible', 'toggle')" />
    </div>
    <!-- Settings -->
    <div class="settingsHeader">
      <i class="pi pi-sliders-h" style="font-size: 16px" />
      Design
    </div>
    <hr />
    <br />

    <!-- Customize -->
    <div class="settingsHeader">
      <i class="pi pi-home" style="font-size: 16px" />
      Customize
      <br />
      <br />
      Building Width: {{(buildingWidth / 12).toFixed(2)}} feet
      <br />
      <br />
      <Slider 
        :value="buildingWidth"
        :max="32 * 12"
        v-on:change="updateModelProp('buildingWidth', $event)"
      />
      <br />
      Building Height: {{(buildingHeight / 12).toFixed(2)}} feet
      <br />
      <br />
      <Slider
        :value="buildingHeight"
        :max="32 * 12"
        v-on:change="updateModelProp('buildingHeight', $event)"
      />
      <br />
      Building Depth: {{(buildingDepth / 12).toFixed(2)}} feet
      <br />
      <br />
      <Slider
        :value="buildingDepth"
        :max="32 * 12"
        v-on:change="updateModelProp('buildingDepth', $event)"
      />
      <br />
    </div>
    <hr />
    <br />

    <!-- Visibility -->
    <div class="settingsHeader">
      <i class="pi pi-eye" style="font-size: 16px" />
      Visibility
      <br />
      <br />
      Model:
      <InputSwitch :value="modelVisible" v-on:change="updateModelProp('modelVisible', 'toggle')"/>
      <br />
      <br />
      __Grid:
      <InputSwitch :value="gridVisible" v-on:change="updateModelProp('gridVisible', 'toggle')"/>
      <br />
      <br />
      __Axes:
      <InputSwitch :value="axesVisible" v-on:change="updateModelProp('axesVisible', 'toggle')"/>
    </div>
    <hr />
    <br />

    <!-- Camera -->
    <div class="settingsHeader">
      <i class="pi pi-camera" style="font-size: 16px" />
      Camera
      <br />
      <br />
      <div v-if="cameraType === true">
        <Button class="designButton" label="Perspective" @click="updateModelProp('cameraType', 'toggle')"/>
      </div>
      <div v-else>
        <Button class="designButton" label="Orthographic" @click="updateModelProp('cameraType', 'toggle')"/>
      </div>
      <br />
    </div>
    <hr />
    <br />

    <!-- Colors -->
    <div class="settingsHeader">
      <i class="pi pi-palette" style="font-size: 16px" />
      Color
      <br />
      <br />
    </div>
    <hr />
    <br />

  </Sidebar>
</template>

<script>
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Sidebar from "primevue/sidebar";
import Slider from "primevue/slider";

export default {
  name: "DesignSettings",
  components: {
    Button,
    InputSwitch,
    Sidebar,
    Slider,
  },
  props: [
    "settingsVisible",
    "modelVisible",
    "cameraType",
    "gridVisible",
    "axesVisible",
    "buildingWidth",
    "buildingHeight",
    "buildingDepth",
  ],
  methods: {
    updateModelProp(prop, event){
      this.$emit("updateModelProp", [prop, event]);
    }
  }
};
</script>

<style scoped>
#closeButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

Button {
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px;
}

.p-button:enabled:hover {
  color: #EC4D37;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.p-button:focus {
  box-shadow: none;
}

.designButton {
  border: 1px solid #ffffff;
  padding: 10px 20px;
  background: #383838;
}

.designButton:enabled:hover {
  border: 1px solid #EC4D37;
  padding: 10px 20px;
  background: #383838;
}

.settingsHeader {
  text-align: center;
}

.p-sidebar {
  margin-top: 42px;
  background-color: #1e1e1e !important;
  box-shadow: none !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #EC4D37;
}

.p-slider {
  margin: 0px 2vh 0px 2vh;
}

.p-inputtext {
  text-align: center;
}
</style>