<template>
  <div id="app">
    <!-- ROUTER VIEW -->
    <div id="routerView">
      <router-view/>
    </div> 
  </div>
</template>

<script>
import "primeicons/primeicons.css";
import "./themes/theme.css";

export default {
  name: "App",
  data() {
    return {
      settingsVisible: false,
      modelVisible: true,
    };
  },
  methods: {
    toggleSetting(settingName) { this[settingName] = !this[settingName]; },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.p-sidebar-content {
  position: static !important;
}

.p-menubar .p-submenu-list {
  z-index: 3000 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #383838 !important;
}
</style>
