<template>
  <Menubar id="navBar" :model="items">
    <template #end>
      <Button
        label="Login"
        icon="pi pi-sign-in"
        :style="{ 'margin-left': '0 .5em' }"
        @click="toggleLogin"
      />
    </template>
  </Menubar>
</template>

<script>
import Button from 'primevue/button';
import Menubar from "primevue/menubar";

export default {
  name: "NavBar",
  components: {
    Button,
    Menubar,
  },
  props: ["loginVisible"],
  methods: {
    toggleLogin(){
      this.$emit("updateModelProp", ["loginVisible", "toggle"]);
    }
  },
  data() {
    return {
      items: [
        {
          label: "File",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "New",
              icon: "pi pi-fw pi-plus",
            },
            {
              label: "Load",
              icon: "pi pi-fw pi-folder-open",
            },
            {
              label: "Save",
              icon: "pi pi-fw pi-save",
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-trash",
            },
            {
              separator: true,
            },
            {
              label: "Share",
              icon: "pi pi-fw pi-cloud-upload",
            },
            {
              label: "Search",
              icon: "pi pi-fw pi-search",
            },
          ],
        },
        {
          label: "Design",
          icon: "pi pi-fw pi-sliders-h",
          command: () => {
            this.$emit("updateModelProp", ["settingsVisible", "toggle"]);
          },
        },
        {
          label: "Materials",
          icon: "pi pi-fw pi-list",
          command: () => {
            console.log("show Materials list...");
          },
        },
        {
          label: "Build",
          icon: "pi pi-fw pi-building",
          command: () => {
            console.log("show Build menu...");
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
#navBar {
  width: 100vw;
  height: 42px;
}

.p-menubar {
  padding: 0px;
}
</style>