<template>
  <div id="viewer"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import House from "./../utilities/House";

export default {
  name: "WebGLViewer",

  props: [
    "modelVisible",
    "cameraType",
    "gridVisible",
    "axesVisible",
    "buildingWidth",
    "buildingHeight",
    "buildingDepth",
    "floorBeamStudSize",
    "joistStudSize",
    "rafterStudSize",
    "roofSheathingSize",
  ],

  data() {
    return {
      webGLRenderer: new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      }),
      scene: undefined,
      camera: undefined,
      perspectiveCamera: undefined,
      orthographicCamera: undefined,
      orbitControls: undefined,
      frameId: undefined,
      container: undefined,
    };
  },

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById("viewer");

      // Scene
      this.scene = new THREE.Scene();

      // Perspective Camera
      this.perspectiveCamera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        window.innerWidth / window.innerHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      );

      // Orthographic Camera
      this.orthographicCamera = new THREE.OrthographicCamera( 
        45 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        45 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        10000 // far - Camera frustum far plane
      );

      // Active Camera
      this.setCamera();
      this.scene.add(this.camera);

      // Build axes and grid
      this.buildSceneHelpers();
      this.webGLRenderer.setClearColor(0xffffff, 1);

      // Geometry
      this.rebuild();

      // Render
      this.setRendererSize([
        this.container.offsetWidth,
        this.container.offsetHeight,
      ]);
      this.container.appendChild(this.webGLRenderer.domElement);

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));
    },

    rebuild() {
      // Remove
      const oldHouse = this.scene.getObjectByName("house");
      if (oldHouse !== undefined) {
        this.scene.remove(oldHouse);
      }
      // Build
      const house = House.Build(
        this.buildingWidth,
        this.buildingDepth,
        this.buildingHeight,
        this.floorBeamStudSize,
        this.joistStudSize,
        this.rafterStudSize,
        this.roofSheathingSize,
      )
      house.visible = this.modelVisible;
      this.scene.add(house);
    },

    setCamera() {
      // Perspective
      if (this.cameraType === true) { 
        this.camera = this.perspectiveCamera;
        this.camera.zoom = 1;
      } // Orthographic
      else {
        this.camera = this.orthographicCamera;
        // TODO - is there a better conversion to replace this hardcoded solution
        this.camera.zoom = 0.16;
      }
      this.camera.position.set(0, 0, 700);
      this.camera.updateProjectionMatrix();
      // Controls
      if(this.orbitControls !== undefined) {
        this.orbitControls.dispose();
      }
      this.orbitControls = new OrbitControls(
        this.camera,
        this.webGLRenderer.domElement
      );
      this.orbitControls.update();
    },

    // Update renderer size via an emitted event upward from a child component
    setRendererSize(value) {
      this.webGLRenderer.setSize(value[0], value[1]);
    },

    // Main render function
    render: function () {
      this.webGLRenderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.1,
      });

      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      let gridLines = new THREE.LineSegments(
        gridHelper,
        gridMaterial,
        THREE.LineSegments
      );
      gridLines.name = "grid";
      gridLines.visible = this.gridVisible;
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.visible = this.axesVisible;
      axesHelper.translateY(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },

    onWindowResize: function () {
      // Update perspective camera
      this.perspectiveCamera.aspect = window.innerWidth / window.innerHeight;
      this.perspectiveCamera.updateProjectionMatrix();

      // Update orthographic camera
      this.orthographicCamera.left = 80 * window.innerWidth / window.innerHeight / - 2;
      this.orthographicCamera.right = 80 * window.innerWidth / window.innerHeight / 2;
      this.orthographicCamera.top = 80 / 2;
      this.orthographicCamera.bottom = 80 / - 2;
      this.orthographicCamera.updateProjectionMatrix();

      this.webGLRenderer.setSize(window.innerWidth, window.innerHeight);
    },

    /*
    onMouseMove: function(event) {
        event.preventDefault();
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
    */
  },

  watch: {
    modelVisible: function() {
      const house = this.scene.getObjectByName("house");
      house.visible = !house.visible;
    },
    cameraType: function() {
      this.setCamera();
    },
    gridVisible: function() {
      const grid = this.scene.getObjectByName("grid");
      grid.visible = !grid.visible;
    },
    axesVisible: function() {
      const axes = this.scene.getObjectByName("axes");
      axes.visible = !axes.visible;
    },
    buildingWidth: function() {
      this.rebuild();
    },
    buildingHeight: function() {
      this.rebuild();
    },
    buildingDepth: function() {
      this.rebuild();
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init();
    this.render();
  },

  // Dispose
  beforeUmount: function () {
    // Unsubscribe
    window.removeEventListener("resize", this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  },
};
</script>

<style scoped>
#viewer {
  z-index: -1;
}
</style>