import Lumber from "./Lumber"
import {
	Group,
	Vector3,
} from "three";

export default {
	Build(baseWidth, baseDepth, BeamStudSize) {
		let centerBeam = new Group();
		centerBeam.name = "floorBeam";

		const [beamStudThickness, beamStudWidth] = Lumber.Studs[BeamStudSize];

    let beam1 = Lumber.BuildStud(BeamStudSize, baseDepth);
		if (beam1 !== false) {
			const start = new Vector3(0, 0, 0);
			const end = new Vector3(0, 0, baseDepth);
			const normal = new Vector3(0, 0, 1);
			beam1 = Lumber.Align(beam1, start, end, normal);
			beam1 = Lumber.Translate(beam1, -beamStudWidth/2, 0, 0);
			centerBeam.add(beam1[0], beam1[1]);
		}

		let beam2 = Lumber.BuildStud(BeamStudSize, baseDepth);
		if (beam2 !== false) {
			const start = new Vector3(0, 0, 0);
			const end = new Vector3(0, 0, baseDepth);
			const normal = new Vector3(0, 0, 1);
			beam2 = Lumber.Align(beam2, start, end, normal);
			beam2 = Lumber.Translate(beam2, -beamStudWidth/2, -beamStudThickness, 0);
			centerBeam.add(beam2[0], beam2[1]);
		}

		let beam3 = Lumber.BuildStud(BeamStudSize, baseDepth);
		if (beam3 !== false) {
			const start = new Vector3(0, 0, 0);
			const end = new Vector3(0, 0, baseDepth);
			const normal = new Vector3(0, 0, 1);
			beam3 = Lumber.Align(beam3, start, end, normal);
			beam3 = Lumber.Translate(beam3, -beamStudWidth/2, beamStudThickness, 0);
			centerBeam.add(beam3[0], beam3[1]);
		}

    const leftBeam = centerBeam.clone();
    leftBeam.position.x -= (baseWidth / 2) - (beamStudThickness * 1.5); // half of 3 beams

    const rightBeam = centerBeam.clone();
    rightBeam.position.x += (baseWidth / 2) - (beamStudThickness * 1.5); // half of 3 beams

    let floorBeams = new Group();
    // TODO - automatically add more beams using linear array
    floorBeams.add(leftBeam, rightBeam);
    
		return floorBeams;
	}
}